import { magic } from "lib/magic";
import { useRouter } from "next/router";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type User = {
  loading?: boolean;
  issuer?: string;
  email?: string;
};
type UserAuthContextType = {
  user: User;
  login: (email: string) => void;
  logout: () => void;
};

const UserAuthContext = createContext<UserAuthContextType>(
  {} as UserAuthContextType
);

const UserAuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>({ loading: true });
  const { push } = useRouter();

  const login = async (email: string) => {
    setLoading(true);
    try {
      // @ts-ignore
      const didToken = await magic.auth.loginWithMagicLink({
        email,
      });

      const res = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${didToken}`,
        },
      });

      if (res.ok) {
        // @ts-ignore
        const magicUser = await magic.user.getMetadata();
        setUser(magicUser);
        push("/user");
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const logout = async () => {
    try {
      // @ts-ignore
      await magic.user.logout();
      // @ts-ignore
      setUser(null);
      push("/login");
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // @ts-ignore
    magic.user.isLoggedIn().then((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        setLoading(true);
        // @ts-ignore
        magic.user.getMetadata().then((magicUser) => {
          setUser(magicUser);
          setLoading(false);
        });
      } else {
        // @ts-ignore
        setUser(null);
      }
    });
  }, []);

  const values = { user };
  const methods = { login, logout };

  return (
    <UserAuthContext.Provider value={{ ...values, ...methods }}>
      {children}
    </UserAuthContext.Provider>
  );
};

const useUserAuthContext = () => useContext(UserAuthContext);

export { UserAuthContextProvider, useUserAuthContext };
export default UserAuthContext;
