import { Magic } from "magic-sdk";

const KEY = "pk_live_1BF28C9621433896";

const createMagic = (key: string) => {
  // We make sure that the window object is available
  // Then we create a new instance of Magic using a publishable key
  return typeof window !== "undefined" && new Magic(key);
};

// Pass in your publishable key from your .env file
export const magic = createMagic(KEY);
